@use '../config/' as *;
@forward './common';
// avtar section
.avatar-section {
    width: 4.5rem;
    flex-shrink: 0;
    @extend %mr-2;
}
.show-avatar {
    width: 4.5rem;
    height: 4.5rem;
    aspect-ratio: 1/1;
    flex-shrink: 0;
    @extend %circle-radius;
    @extend %relative;
    &::after {
        width: 1.6rem;
        height: 1.6rem;
        position: absolute;
        bottom: 15%;
        right: -5%;
        @include icon(camera, 8);
        @extend %white;
        @extend %flex-c-c;
        @extend %circle-radius;
        @extend %primary-bg;
    }
}
.avatar {
    &-list {
        @include card-count(4, var(--space-3));
        @extend %flex-wrap;
        @extend %flex;
    }
    &-item {
        aspect-ratio: 1/1;
    }
    &-input {
        @extend %d-none;
        &:checked ~ .avatar-img {
            @include border(3, primary);
            @extend %accent-bg;
        }
    }
    &-img {
        aspect-ratio: 1/1;
        @include border(1, secondary, 2);
        @extend %transition;
        @extend %circle-radius;
        @extend %w-100;
        @extend %primary-bg-1;
    }
}
// styles specifically for complete profile 
.waf-complete-profile {
    @extend %white-bg;
}
.complete-profile {
    .otp-wrap {
        @extend %mb-3;
    }
    .form {
        &-head {
            @extend %flex;
        }
        &-control {
            @extend %p-2;
        }
        &-wrapper {
            overflow: unset;
            @extend %m-4-0;
            @extend %p-0;
        }
        &-body {
            overflow: auto;
            height: calc(100% - 11rem);
            @include custom-scroll;
            @extend %my-2;
            @extend %pr-4;
        }
    }
    .custom-form {
        gap: 0 var(--space-2);
    }
    .otp-verify-section {
        overflow: hidden;
        @extend %flex-wrap;
        @extend %gap-2;
        @extend %flex;
    }
    .mobile-number-wrapper {
        .form-control {
            @extend %p-2;
        }
    }
}
// Step section
.step-section {
    position: absolute;
    top: 1%;
    left: 5%;
    z-index: var(--z-step);
    .step-label {
        @extend %font-12;
        @extend %black;
    }
    .current-step {
        font-weight: 600;
        @extend %primary;
    }
    .btn-skip {
        @extend %d-none;
    }
}
// styles specififcally for personalise your exp
.personalise-profile {
    .form {
        &-wrapper {
            overflow: unset;
            @extend %m-0;
            @extend %p-0;
        }
        &-body {
            overflow: auto;
            height: calc(100% - 11rem);
            @include custom-scroll;
            @extend %my-2;
            @extend %pr-4;
        }
    }
    .step-section {
        @extend %w-90;
        @extend %flex-sb-c;
    }
    .btn-skip {
        position: absolute;
        top: -10rem;
        right: -2rem;
        @extend %font-16-pb;
        @extend %white;
        @extend %uppercase;
    }
}
@include mq(col-md) {
    // avatar section 
    .show-avatar {
        width: 7rem;
        height: 7rem;
        &::after {
            width: 2rem;
            height: 2rem;
        }
    }
    .avatar {
        &-section {
            width: 7rem;
            .modal-body-wrap {
                max-width: 80rem;
                margin-top: calc((var(--header-height)/ 2) * -1);
            }
        }
        &-list {
            @include card-count(6, var(--space-4));
        }
    }
    // personalise profile
    .personalise-profile {
        .step {
            &-section {
                width: auto;
                top: 6rem;
            }
        }
        .form-wrapper {
            margin-left: var(--space-10-neg);
        }
        .btn-skip {
            position: absolute;
            top: -5rem;
            right: -2.5rem;
        }
        .club {
            &-list {
                padding: var(--space-2);
                background-color: hsl(var(--hsl-white)/0.05);
                @include backdropBlur(.5rem);
            }
        }
    }
    // styles specifically for complete profile 
    .step-section {
        top: 10rem;
        right: 4rem;
        left: unset;
        .step-label {
            font-size: 1.4rem;
            color: var(--white);
        }
        .current-step {
            color: var(--accent);
        }
    }
    .complete-profile {
        .form-mail {
            order: -1;
        }
        .form-mobile {
            order: -1;
        }
    }
}